import React from 'react'
import SEOTools from '../components/SEOTools'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const TrialExpiredPage = () => {
    return (
        <>
            <SEOTools title='Trial expired' />
            <section className='full-screen-demo-section'>
                <iframe
                    title='Trial expired'
                    className='w-100 border-0 full-screen-demo'
                    src='https://framer.com/embed/PINK-PAY--p4z8i122OqaS3oNJrVG2/fdQ1SZANc?highlights=0'
                />
            </section>
        </>
    )
}

export default TrialExpiredPage
